<template>
  <!-- 中台管理账号 -->
  <div class="container">
    <div class="main-content">
      <div class="main-content-header">
        <div class="item">
          <span class="title">管理账号</span>
        </div>
        <div class="item">
          <a-button type="primary" @click="onAdd">
            新增账号
          </a-button>
        </div>
      </div>
      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :loading="loading"
        :scroll="{ x: 1280, y: true }"
      >
        <template slot="status" slot-scope="record">
          <a-badge
            :status="statuText[record.status].statu"
            :text="statuText[record.status].text"
          />
        </template>
        <template slot="action" slot-scope="record">
          <router-link :to="`asd?id=${record.id}`">
            查看账号
          </router-link>
        </template>
      </my-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import myTable from '@/components/my-table'
export default {
  data() {
    return {
      dataSource: [],
      columns: [
        {
          title: '序号',
          width: 60,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '登录账号',
          dataIndex: 'systemNum',
          width: 220
        },
        {
          title: '持有者姓名',
          dataIndex: 'username',
          width: 150,
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '所属角色',
          dataIndex: 'role_name',
          width: 150,
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '角色等级',
          dataIndex: 'level_name',
          width: 100,
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createAt',
          width: 200
        },
        {
          title: '账号状态',
          scopedSlots: { customRender: 'status' },
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          customRender: (text) => {
            return text ? text : '-'
          }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loading: true,
      statuText: [
        {
          text: '禁用',
          statu: 'default'
        },
        {
          text: '启用',
          statu: 'success'
        }
      ]
    }
  },
  computed: {
    ...mapState(['pageTitle'])
  },
  components: {
    myTable
  },
  created() {
    this.getAccount()
  },
  methods: {
    getAccount() {
      // 获取账号列表
      const data = {
        type: 'Madmin'
      }
      this.$axios.getAccount(data).then((res) => {
        this.dataSource = res.data.data
        this.loading = false
      })
    },
    onAdd() {
      this.$router.push('/accountAdd')
    }
  }
}
</script>
